import React from 'react';
import { Helmet } from 'react-helmet';
import HelpList from '../components/helpList/helpList';
import BootstrapNavbar from '../components/navgationBar/navigationBar';
import GlobalStyles from '../styles/globalStyles';
export default function Help() {
  return (
    <>
      <GlobalStyles />
      <Helmet>
        <title>Help | Pro2Go</title>
        <meta
          name="viewport"
          content="width=device-width, initial-scale=1.0"
        ></meta>
        <meta name="format-detection" content="telephone=no"></meta>
        <meta
          name="description"
          content="Help regarding our bbq propane cylinder exchange services, in London Ontario, Canada"
        ></meta>
        <meta
          name="keywords"
          content="bbq cylinders,propane cylinders,cylinder exchange service,london ontario,canad."
        ></meta>
      </Helmet>
      <BootstrapNavbar />
      <HelpList />
    </>
  );
}
